<template>
    <el-container style="height: 1000px; border: 1px solid #eee">
      <el-header>
          <pageHeader @cityChanged="cityChanged"></pageHeader>
      </el-header>
      <el-main>
        <el-row>
          <el-col :span="24">
            <menuBar @menuSelected='menuSelected'/>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
          <IndexMainPage v-show="panel=='index'" :chooseCity="chooseCity" @switchPanel="switchPanel" ></IndexMainPage>
          <SearchPanel v-show="panel=='search'" :chooseCity="chooseCity" :propertyColumn="column"></SearchPanel>
          <PropertyDetailPanel v-show="panel=='detail'" :property="detailProperty"></PropertyDetailPanel>
          </el-col>

         
        </el-row>

      
    
      </el-main>
      <el-footer>
        <pageFooter></pageFooter>
      </el-footer>
    </el-container>
</template>

<script>

 import {getUrlKey} from '@/utils/utils'
 import {bindToGlobal} from '@/utils/global'
 import {areaCodeMap} from '@/api/commonFunc'
 import pageHeader from '../../components/pc/header'
 import pageFooter from '../../components/pc/footer'
 import menuBar from '../../components/pc/menuBar'
 import IndexMainPage from '../../components/pc/index/IndexMainPage'
 import propertyCard from '../../components/pc/propertyCard'
 import SearchPanel from '../../components/pc/index/SearchPanel'
import PropertyDetailPanel from '../../components/pc/detail/PropertyDetailPanel'
import {getPropertyDetail} from '@/api/propertyQuery'
    export default {
        name: "index",
        components: {
                pageHeader,
                pageFooter,
                menuBar,
                IndexMainPage,
                propertyCard,
                SearchPanel,
                PropertyDetailPanel
               
        },
		data(){
			return {
        panel:'index',
        chooseCity:'',
        column:'',
        detailProperty:{}
			}
    },
    created() {
      areaCodeMap().then(res=>{
        bindToGlobal(res.data,"areaCodeNameMapping");
      });
       let propertyId = getUrlKey('propertyId', window.location.href);
       
       if(propertyId){
         this.panel='detail';
          getPropertyDetail(propertyId).then(res=>{
            this.detailProperty = res.data

          })
       }
        
    },
    methods:{
      menuSelected(key){
          console.log(key)
          if(key ==='indexPage'){
            this.panel='index';
          }else{
            this.panel = 'search';
            if(key !='万科' && key!='碧桂园'){
                this.column = key;
            }
            
          }
          
      },
      cityChanged(city){
        this.chooseCity = city;
      },
      switchPanel(panelName){
        this.panel = panelName;
        console.log(this.panel);
      }
    }
    }
</script>

<style scoped>
/* .el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;

  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
    height: 600px;
  } */




</style>
