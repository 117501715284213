import Vue from "vue";
import VueRouter  from "vue-router";
 


 
Vue.use(VueRouter);
 
const routes = [
 


   
	
	
];

//export default new Router({
//   mode:"history",
//    base:process.env.BASE_URL,
//    routes
//});

/**
 * 判断是否为移动设备，是，则跳转到移动端的路径
 */


const router=new VueRouter({
    routes  
});


/**
 *  pc 设备验证
 */
router.beforeEach((to, from, next) => {
	
  next() 
 
})

export default router
