import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import App from '../../pages/pc/index.vue';
import router from "../../router/pc/index.js";
import Axios  from "axios";
import ElementUI from 'element-ui';
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'RIEz8WC1CgyltnD6NFfKsm0GkFGuRz9o'
})

Vue.use(ElementUI,{ size: 'small', zIndex: 3000 });

Vue.prototype.axios = Axios;
Vue.config.productionTip = false;
new Vue({
	render:h=>h(App),
 router:router}).$mount('#index');
